import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useDebounce from './useDebounce';
import clsx from 'clsx';

import { mergeRefs } from '@workhuman/react-aurora-utils';

import styles from './Listbox.module.scss';

/**
 * Search options that starts with some query string in specific range.
 *
 * @param {Array} items listBoxData options array.
 * @param {Number} startIndex
 * @param {Number} endIndex
 * @param {String} searchQuery
 * @param {String} searchBy field to search by.
 */
const searchIndexByString = ({
    items,
    startIndex,
    endIndex,
    searchQuery,
    searchBy,
}) => {
    const searchItems = searchBy ? items.map((item) => item[searchBy]) : items;

    for (let i = startIndex; i < endIndex; i++) {
        if (
            searchItems[i].toString().toUpperCase().indexOf(searchQuery) === 0
        ) {
            return i;
        }
    }

    return null;
};

export const Listbox = React.memo(
    React.forwardRef((props, ref) => {
        const {
            id,
            selectedValueIndex,
            listBoxData,
            label,
            labelledBy,
            listBoxSize,
            searchBy,
            onClick,
            onKeyDown,
            renderItem = () => {},
            className,
            itemClassName,
            ...htmlProps
        } = props;

        const targetRef = React.useRef();
        const [activeDescendantIndex, setActiveDescendantIndex] = useState(
            selectedValueIndex ? selectedValueIndex : 0
        );
        const [listBoxMaxHeight, setMaxHeight] = useState({
            maxHeight: undefined,
        });
        const [typedString, setTypedString] = useState('');
        const typeAheadString = useDebounce(typedString, 500);
        // necessary for keyboard navigation in combobox
        useEffect(() => {
            selectedValueIndex !== undefined &&
                setActiveDescendantIndex(selectedValueIndex);
        }, [selectedValueIndex]);

        /**
         * In order to avoid long lists in components like `combobox` we need
         * to support max-height. This is based on amount of elements.
         *
         * Current implementation assumes that listbox items will have same size,
         * but if we need to change that we can do different calculation.
         */

        useEffect(() => {
            setMaxHeight({
                maxHeight: listBoxSize
                    ? targetRef.current.children[0].clientHeight * listBoxSize
                    : undefined,
            });
        }, []);

        /**
         * Search for options "below" and "above" current
         * when typeAheadString is changed.
         */
        useEffect(() => {
            const matchIndex =
                searchIndexByString({
                    items: listBoxData.options,
                    startIndex: activeDescendantIndex,
                    endIndex: listBoxData.options.length,
                    searchQuery: typeAheadString,
                    searchBy: searchBy,
                }) ||
                searchIndexByString({
                    items: listBoxData.options,
                    startIndex: 0,
                    endIndex: activeDescendantIndex,
                    searchQuery: typeAheadString,
                    searchBy: searchBy,
                });

            if (matchIndex !== null) {
                setActiveDescendantIndex(matchIndex);
            }
        }, [typeAheadString]);

        /**
         * Scroll listbox scroll to new focused item if it doesn't visible in listbox.
         * When listbox height less than height of it's internal items and it has scroll.
         *
         * Scrolling only works as expected if the listbox is the options' offsetParent.
         * This implementation uses position: relative on the listbox to that effect.
         *
         * - When an option is focused that isn't (fully) visible, the listbox's scroll position is updated:
         *   If Up Arrow or Down Arrow is pressed, the previous or next option is scrolled into view.
         *
         * - If Home or End is pressed, the listbox scrolls all the way to the top or to the bottom.
         *
         * - If focusItem is called, the focused option will be scrolled to the top of the view
         *   if it was located above it or to the bottom if it was below it.
         *
         * - If the mouse is clicked on a partially visible option, it will be scrolled fully into view.
         *   When a fully visible option is focused in any way, no scrolling occurs.
         *
         * - Normal scrolling through any scrolling mechanism (including Page Up and Page Down) works as expected.
         *   The scroll position will jump as described for focusItem if a means other than a mouse click is used
         *   to change focus after scrolling.
         *
         * */

        useEffect(() => {
            const listBoxNode = targetRef.current;
            const selectedNode = listBoxNode.children[activeDescendantIndex];
            if (listBoxNode.scrollHeight > listBoxNode.clientHeight) {
                const scrollBottom =
                    listBoxNode.clientHeight + listBoxNode.scrollTop;
                const elementBottom =
                    selectedNode.offsetTop + selectedNode.offsetHeight;

                /**
                 * Check if new focused element below bottom edge or above top edge of listbox.
                 * If so, change listbox scroll position to see new focused element fully.
                 */
                if (elementBottom > scrollBottom) {
                    listBoxNode.scrollTop =
                        elementBottom - listBoxNode.clientHeight;
                } else if (selectedNode.offsetTop < listBoxNode.scrollTop) {
                    listBoxNode.scrollTop = selectedNode.offsetTop;
                }
            }
        }, [activeDescendantIndex]);

        /**
         * Keyboard functionality for listbox.
         *
         * | Key        | Function                                        |
         * | ---------- | ----------------------------------------------- |
         * | Down Arrow | Moves focus to and selects the next option.     |
         * | Up Arrow   | Moves focus to and selects the previous option. |
         * | Home       | Moves focus to and selects the first option.    |
         * | End        | Moves focus to and selects the last option.     |
         *
         * @see https://www.w3.org/TR/wai-aria-practices-1.1/#listbox_kbd_interaction
         *
         * @param {key} key code of event
         * @param {last} last element index in array
         * @param {active} active element index
         * @param {KeyboardEvent} event keydown event.
         */

        const updateActive = (event, active, { key, last }) => {
            const keys = {
                TAB: 9,
                RETURN: 13,
                END: 35,
                HOME: 36,
                UP: 38,
                DOWN: 40,
            };
            switch (key) {
                case keys.UP:
                    event.preventDefault();
                    return active === 0 ? 0 : active - 1;
                case keys.DOWN:
                    event.preventDefault();
                    return active === last ? last : active + 1;
                case keys.END:
                    event.preventDefault();
                    return last;
                case keys.HOME:
                    event.preventDefault();
                    return 0;
                case keys.RETURN:
                    event.preventDefault();
                    if (onKeyDown) {
                        onKeyDown(activeDescendantIndex);
                    }
                    return active;
                default:
                    return active;
            }
        };

        /**
         * Set active listobx item on keydown events
         *
         *  @param {KeyboardEvent} event keydown event.
         */
        const setActiveListItem = (event) => {
            const listBoxDescendantsLength = listBoxData.options.length;
            const updatedActiveElement = updateActive(
                event,
                activeDescendantIndex,
                { key: event.keyCode, last: listBoxDescendantsLength - 1 }
            );
            setTypedString(
                typeAheadString + String.fromCharCode(event.keyCode)
            );
            setActiveDescendantIndex(updatedActiveElement);
        };

        return (
            <ul
                ref={mergeRefs(ref, targetRef)}
                id={`${id}_listbox`}
                className={clsx(
                    styles['a-listbox'],
                    styles['a-typography--body1'],
                    className
                )}
                role="listbox"
                tabIndex="0"
                aria-label={label}
                aria-labelledby={labelledBy}
                aria-activedescendant={`listbox_${id}_option_${activeDescendantIndex}`}
                onKeyDown={setActiveListItem}
                style={listBoxMaxHeight}
                {...htmlProps}
            >
                {listBoxData.options.map((option, index) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <li
                            className={clsx(
                                styles['a-listbox-item'],
                                itemClassName
                            )}
                            data-index={index}
                            role="option"
                            id={`listbox_${id}_option_${index}`}
                            aria-selected={activeDescendantIndex === index}
                            key={index}
                            onClick={() => {
                                if (onClick) {
                                    onClick(index);
                                }
                                setActiveDescendantIndex(index);
                            }}
                        >
                            {renderItem(option) === undefined
                                ? option
                                : renderItem(option)}
                        </li>
                    );
                })}
            </ul>
        );
    })
);

Listbox.displayName = 'Listbox';

Listbox.propTypes = {
    /**
     * Listbox id
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Array of options with data to represent
     */
    listBoxData: PropTypes.object.isRequired,

    /**
     * Index of initially selected option in array (in select or combobox)
     */
    selectedValueIndex: PropTypes.number,

    /**
     * Listbox description. Aria-label value
     */
    label: PropTypes.string,

    /**
     * Aria-labelledby attribute value
     * DOM Node id expected.
     */
    labelledBy: PropTypes.string,

    /**
     * Listbox function that renders markup for listbox option
     */
    renderItem: PropTypes.func,

    /**
     * Field name to search by.
     */
    searchBy: PropTypes.string,

    /**
     * This attribute represents the number of rows
     * in the list that should be visible at one time.
     */
    listBoxSize: PropTypes.number,

    /**
     * Listbox item onClick method,
     * necessary to select value on click and set it to menu button in select/combobox
     */
    onClick: PropTypes.func,

    /**
     * Listbox onKeyDown method
     * necessary to select value on Enter and set it to menu button in select/combobox
     */
    onKeyDown: PropTypes.func,

    /**
     * @ignore
     */
    className: PropTypes.string,

    /**
     * @ignore
     */
    itemClassName: PropTypes.string,
};
