import React from 'react';
import PropTypes from 'prop-types';

import styles from './TextInput.module.scss';

export const TextInputLabel = React.memo((props) => {
    const { htmlFor, label } = props;

    return (
        <label htmlFor={htmlFor} className={styles['a-input-label']}>
            {label}
        </label>
    );
});

TextInputLabel.displayName = 'TextInputLabel';

TextInputLabel.propTypes = {
    /**
     * Input id
     */
    htmlFor: PropTypes.string.isRequired,

    /**
     * Input label
     */
    label: PropTypes.string.isRequired,
};
