import 'whatwg-fetch';
import { logError } from './log-error';


const appIdLocation = '/env-props/properties.json';
const guidPattern = /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/;

/**
 * @function rewriteFunc
 *
 * https://docs.heapanalytics.com/v1.0/docs/heapjs-rewrite-api#section-strip-the-alt-text-on-an-image-click-
 * If the DOM hierarchy is captured, and it contains PII attributes, strip the text of these attributes.
 *
 * @param {*} props - inner heap object
 */
const rewriteFunc = function (props) {
    const attributesListPattern = /\[(alt|title|href|src|data-.*?|aria-.*?|i18n-.*?|placeholder)=[^\]]+]/g; // Pattern contains PII leak attributes which should be redacted;
    // Simple attributes such as 'alt' should be added via OR condition '|',
    // Attributes with the dash such as 'data-*' should have also expression '.*?' for the end of the word ('data-person')
    // ! Make sure to update README after adding new attributes.
    const classesListPatternForHierarchyRedaction = /chartUser|chartPopup|award-lightbox-info|accountViewers|circle-graph-container|gf-selected-person/g; // Classes which mark PII danger hierarchy
    const classesListPatternForTargetClassRedaction = /award-lightbox-info/g;
    const classesListPatternForTargetIdRedaction = /circle-graph-container|gf-selected-person/g;

    const originalEventHierarchy = props.event_properties.hierarchy;
    if (originalEventHierarchy) {
        props.event_properties.hierarchy = classesListPatternForHierarchyRedaction.test(originalEventHierarchy) ?
            '_redacted' // heap UI truncates the first character of the hierarchy so in case of redacting whole hierarchy we add _ at the beginning to display that well in their's UI
            : originalEventHierarchy.replace(attributesListPattern, '[$1=redacted]');
    }
    if (classesListPatternForTargetClassRedaction.test(originalEventHierarchy)) {
        props.event_properties.target_class = 'redacted';
    }
    if (classesListPatternForTargetIdRedaction.test(originalEventHierarchy)) {
        props.event_properties.target_id = 'redacted';
    }
    // Make sure to return props after we modify them,
    // and also return them if no changes were made.
    return props;
};

/**
 * @function isSafeAnalyticsPersonIdProvided
 *
 * @param {string} analyticsPersonId - safe user id. Must differ from pk_person
 */
const isSafeAnalyticsPersonIdProvided = function (analyticsPersonId) {
    return analyticsPersonId && guidPattern.test(analyticsPersonId);
};

/**
 * @function resetIdentity
 *
 * Resets user's identity in HEAP by using the HEAP API.
 */
const resetIdentity = function () {
    if (window.hasOwnProperty('heap')) {
        heap.resetIdentity();
    }
};

/**
 * @function initAnonymously
 *
 * Checks, whether analytics are enabled and if so, initalizes HEAP JavaScript API by loading the code to the global variable scope.
 *
 * This method returns a promise, which
 * - is rejected when the heap cannot be initialized for whatever reason
 * - is resolved when the heap is initialized and loaded to the browser memory
 *
 * @param webAnalyticsEnabled
 */
const initAnonymously = function (webAnalyticsEnabled) {
    let errObj = {};

    if (!webAnalyticsEnabled) {
        errObj.message = 'Web analytics disabled';
        return Promise.reject(errObj);
    }

    // https://docs.heapanalytics.com/docs/installation

    return fetch(appIdLocation)
        .then((envPropsResponse) => {
            if (!envPropsResponse.ok) {
                errObj.message = 'Failed to load environment app id';
                return Promise.reject(errObj);
            }

            return envPropsResponse.json()
                .then((envProps) => {
                    if (envProps && envProps.webAnalyticsAppId && envProps.webAnalyticsAppId !== '') {
                        // @formatter:off
                        /* eslint-disable */
                        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
                        /* eslint-enable */
                        // @formatter:on

                        heap.load(envProps.webAnalyticsAppId, {
                            disableTextCapture: true,
                            rewrite: rewriteFunc
                        });

                        return Promise.resolve();
                    }

                    errObj.message = 'Unable to retrieve key webAnalyticsAppId from file';
                    return Promise.reject(errObj);
                }, (error) => {
                    errObj.message = error;
                    return Promise.reject(errObj);
                });
        });
};

/**
 * @function identifyWebAnalytics
 *
 * @param {string} whClientId - A case-sensitive string that uniquely identifies a client.
 * @param {string} whClientType - The type of the client (PREMIUM, PLAN1, PLAN2 or PLAN3)
 * @param {string} identity - A case-sensitive string that uniquely identifies a user. Must be fewer than 255 characters and must differ from pk_person.
 * @param {string} whInternalUser - If value is provided, impersonation should be used. Otherwise == null
 * @param {Object} [customDimensions] - Custom properties to user profiles
 * @param {Object} [eventProperties] - Global key-value pairs to get attached to all of a user's subsequent events
 */
const identifyWebAnalytics = function(whClientId, whClientType, identity, whInternalUser, customDimensions = {}, eventProperties = {}) {
    if (!window.hasOwnProperty('heap') || whInternalUser || !whClientType || !isSafeAnalyticsPersonIdProvided(identity)) {
        return;
    }

    if (identity) {
        heap.identify(identity);
    }

    if (whClientId) {
        customDimensions.whClientId = whClientId;
    }
    // No need for an if statement to check whClientType - it's a required field and checked above
    customDimensions.whClientType = whClientType;

    heap.addUserProperties(customDimensions);
    heap.addEventProperties(eventProperties);
};

/**
 * @function initWebAnalytics
 *
 * @param {boolean} webAnalyticsEnabled - Value of g_cfg_web_analytics_enabled field
 * @param {string} whClientId - A case-sensitive string that uniquely identifies a client.
 * @param {string} whClientType - The type of the client (PREMIUM, PLAN1, PLAN2 or PLAN3)
 * @param {string} identity - A case-sensitive string that uniquely identifies a user. Must be fewer than 255 characters and must differ from pk_person.
 * @param {string} whInternalUser - If value is provided, impersonation should be used. Otherwise == null
 * @param {Object} [customDimensions] - Custom properties to user profiles
 * @param {Object} [eventProperties] - Event properties to user profiles
 *
 * @example
 *
 * initWebAnalytics(true, data.clientId, person.externalAnalyticsId, null, {gfClientName: data.client, gfCatalogCountryId: person.countryId }, {mobile_initiated: 'android'});
 */
const initWebAnalytics = function (webAnalyticsEnabled, whClientId, whClientType, identity, whInternalUser, customDimensions = {}, eventProperties = {}) {
    if (!webAnalyticsEnabled || whInternalUser || (!whClientType) || !isSafeAnalyticsPersonIdProvided(identity)) {
        return;
    }

    initAnonymously(webAnalyticsEnabled)
        .then(() => {
            identifyWebAnalytics(whClientId, whClientType, identity, whInternalUser, customDimensions, eventProperties);
        })
        .catch((error) => {
            logError(error);
        });
};

/**
 * @function addCustomProperties
 * Add custom properties to user profiles and attach event properties for anonymous user
 *
 * @param {string} whClientId - A case-sensitive string that uniquely identifies a client.
 * @param {Object} [customDimensions] - Custom properties to user profiles
 * @param {Object} [eventProperties] - Event properties to user profiles
 *
 */
const addCustomProperties = function (whClientId, customDimensions = {}, eventProperties = {}) {
    if (!window.hasOwnProperty('heap')) {
        return;
    }
    if (whClientId) {
        customDimensions.whClientId = whClientId;
    }

    heap.addUserProperties(customDimensions);
    heap.addEventProperties(eventProperties);
};

export { resetIdentity, initAnonymously, initWebAnalytics, rewriteFunc, identifyWebAnalytics, addCustomProperties }; // export rewriteFunc for testing purposes
