import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Loader.module.scss';

/**
 * Generate zebra styled bar items
 */
const generateBars = () => {
    const bars = [];

    for (let i = 0; i < 9; i++) {
        bars.push(
            <div
                className={clsx(
                    styles['a-loader-bar'],
                    styles[`a-loader-bar--${i % 2 === 0 ? 'even' : 'odd'}`]
                )}
                key={i}
            ></div>
        );
    }

    return bars;
};

/**
 * Application loader.
 */
export const Loader = React.memo(
    ({ type = 'bars', className, ...htmlProps }) => {
        const loaderChildren = type === 'bars' && generateBars();

        return (
            <div
                className={clsx(
                    styles['a-loader'],
                    styles[`a-loader--${type}`],
                    className
                )}
                {...htmlProps}
            >
                {loaderChildren}
            </div>
        );
    }
);

Loader.displayName = 'Loader';

Loader.propTypes = {
    /**
     * Loader visual type
     */
    type: PropTypes.string,

    /**
     * @ignore
     */
    className: PropTypes.string,
};
