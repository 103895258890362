import React from 'react';

import { mergeRefs } from '@workhuman/react-aurora-utils';

/**
 * Button hook.
 * Encapsulates generic button attributes and requirements.
 *
 * @param {Object} params hook params.
 * @param {React.Ref} params.ref HTMLNodeElement ref from button target.
 * @param {Object} params.props Object with non native HTML props.
 * @param {Array} params.htmlProps HTML attributes.
 */
export const useButton = function ({
    ref: htmlRef,
    tabIndex: htmlTabIndex = 0,
    ...htmlProps
}) {
    const ref = React.useRef(null);
    const [role, setRole] = React.useState();
    const trulyDisabled = htmlProps.disabled && !htmlProps.focusable;

    /**
     * @see https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
     */
    React.useLayoutEffect(() => {
        if (
            (ref.current && ref.current instanceof HTMLButtonElement) ||
            ref.current instanceof HTMLAnchorElement ||
            ref.current instanceof HTMLInputElement
        ) {
            return;
        }

        setRole('button');
    }, []);

    return {
        ref: mergeRefs(ref, htmlRef),
        role,
        type: 'buton',
        tabIndex: trulyDisabled ? undefined : htmlTabIndex,
        'aria-disabled': htmlProps.disabled,
        ...htmlProps,
        /**
         * Disabled property shuld be handled by combination of `disabled` and `focusable` props.
         */
        disabled: trulyDisabled,
    };
};
