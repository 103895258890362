/**
 * Polyfill "matches" method.
 */
(function (e) {
    var matches =
        e.matches ||
        e.matchesSelector ||
        e.webkitMatchesSelector ||
        e.mozMatchesSelector ||
        e.msMatchesSelector ||
        e.oMatchesSelector;
    !matches
        ? (e.matches = e.matchesSelector = function matches(selector) {
              var matches = document.querySelectorAll(selector);
              var th = this;
              return Array.prototype.some.call(matches, function (e) {
                  return e === th;
              });
          })
        : (e.matches = e.matchesSelector = matches);
})(Element.prototype);

/**
 * Polyfill "contains" method for SVG elements in IE11 browser.
 */
SVGElement.prototype.contains = function contains(node) {
    if (!(0 in arguments)) {
        throw new TypeError('1 argument is required');
    }

    do {
        if (this === node) {
            return true;
        }
    } while ((node = node && node.parentNode));

    return false;
};
