import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './TextInput.module.scss';

export const TextInput = React.memo((props) => {
    const {
        inputSize,
        isVertical,
        innerIcon,
        outerIcon,
        hasOuterIcon,
        hasError,
        hasSuccess,
        className,
        children,
    } = props;

    return (
        <div
            className={clsx(
                styles['a-input'],
                styles[innerIcon ? 'a-input--withControlIcon' : ''],
                styles[outerIcon || hasOuterIcon ? 'a-input--withIcon' : ''],
                styles[isVertical ? 'a-input--vertical' : ''],
                styles[hasError ? 'a-input--hasError' : ''],
                styles[hasSuccess ? 'a-input--hasSuccess' : ''],
                styles['a-input--size' + inputSize],
                className
            )}
        >
            {children}
        </div>
    );
});

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
    inputSize: 'M',
};

TextInput.propTypes = {
    /**
     * Input size
     */
    inputSize: PropTypes.oneOf(['XS', 'S', 'M', 'L', 'XL']),

    /**
     * Indicates if input is vertical
     */
    isVertical: PropTypes.bool,

    /**
     * Indicates if input is invalid
     *
     */
    hasError: PropTypes.bool,

    /**
     * Indicates if input is valid
     */
    hasSuccess: PropTypes.bool,

    /**
     * Indicates if input has success/error icon after validation
     */
    hasOuterIcon: PropTypes.bool,

    /**
     * Input inner icon
     */
    innerIcon: PropTypes.string,

    /**
     * Input outer icon
     */
    outerIcon: PropTypes.string,

    /**
     * Input group child nodes
     */
    children: PropTypes.node,

    /**
     * @ignore
     */
    className: PropTypes.string,
};
