import 'whatwg-fetch';
/**
 * Logs error to server.
 *
 * @param errorObject Error object
 */
export const logError = (errorObject) => {
    let errorData = {
        message: errorObject.message,
        error: errorObject.error,
        page: window.location.href,
        stackframes: errorObject.stack
    };

    // Send error to server
    fetch('/microsites/t_pub/jserror', {
        method: 'POST',
        body: JSON.stringify(errorData),
        headers: {
            'Content-Type': 'text'
        }
    }).then((res) => {
        res.ok ? console.log('Error has been logged.') : console.log('Error has NOT been logged.');
    }).catch((error) => {
        console.log('Error has NOT been logged.');
    });
};
