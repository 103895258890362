/**
 * Makes first letter in the word uppercase.
 *
 * @param {String} string string to capitalize.
 */
export function capitalize(string) {
    if (typeof string !== 'string') {
        throw new Error(
            'Aurora: capitalize(string) expects a string argument.'
        );
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
}
