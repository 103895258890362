import './polyfills';

const selector =
    "input:not([type='hidden']):not([disabled]), select:not([disabled]), " +
    'textarea:not([disabled]), a[href], button:not([disabled]), [tabindex], ' +
    'iframe, object, embed, area[href], audio[controls], video[controls], ' +
    "[contenteditable]:not([contenteditable='false'])";

export function isVisible(el) {
    return (
        el.offsetWidth > 0 &&
        el.offsetHeight > 0 &&
        el.getClientRects().length > 0
    );
}

export function isFocusable(el) {
    return el.matches(selector) && isVisible(el);
}

export function hasNegativeTabIndex(el) {
    let index = parseInt(el.getAttribute('tabIndex') || '0', 10);

    return index < 0;
}

export function isTabbable(el) {
    return isFocusable(el) && !hasNegativeTabIndex(el);
}

function getAllTabbable(container, fallbackToFocusable) {
    let allFocusable = Array.from(container.querySelectorAll(selector));
    let allTabbable = allFocusable.filter(isTabbable);

    if (isTabbable(container)) {
        allTabbable.unshift(container);
    }

    if (!allTabbable.length && fallbackToFocusable) {
        return allFocusable;
    }

    return allTabbable;
}

export function getFirstTabbableIn(container, fallbackToFocusable) {
    let [first] = getAllTabbable(container, fallbackToFocusable);

    return first || null;
}

export function getLastTabbableIn(container, fallbackToFocusable) {
    let allTabbable = getAllTabbable(container, fallbackToFocusable);

    return allTabbable[allTabbable.length - 1] || null;
}

export function ensureFocus(el, { isActive, preventScroll }) {
    if (isActive(el)) return;

    el.focus({ preventScroll });

    if (isActive(el)) return;

    return requestAnimationFrame(() => {
        el.focus({ preventScroll });
    });
}
