import { useEffect, useCallback } from 'react';

/**
 * A hook to handle click outside of an element and close it.
 *
 * @param {RefObject} innerRef Inner element ref.
 * @param {object} options Options object detailed below.
 * @param {RefObject} options.outsideRef Outer element ref.
 * @param {Function} options.hide Hide callback.
 * @param {boolean} options.enabled Enable handling click outside.
 * @param {Array} options.exemptionSelectors Optional list of selectors of exempted DOM elements.
 */
export const useHideOnClickOutside = (
    innerRef,
    { outsideRef, hide, enabled = true, exemptionSelectors = [] }
) => {
    const exemptionsNotClicked = useCallback(
        (target) =>
            !exemptionSelectors.length ||
            (exemptionSelectors.length &&
                exemptionSelectors.every(
                    (selector) => document.querySelector(selector) !== target
                )),
        [...exemptionSelectors]
    );

    useEffect(() => {
        if (!enabled || !hide) return;
        const handleClick = ({ target }) => {
            const { current: innerElement } = innerRef;
            const { current: outsideElement } = outsideRef;
            if (
                innerElement &&
                outsideElement &&
                !outsideElement.contains(target) &&
                !innerElement.contains(target) &&
                exemptionsNotClicked(target)
            ) {
                hide();
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [enabled, outsideRef, innerRef, hide, ...[exemptionSelectors]]);
};
