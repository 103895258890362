import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Icon.module.scss';
import '@aurora/icon/aurora-icons.svg';

export const Icon = React.memo(
    React.forwardRef((props, ref) => {
        const { xlinkHref, name, size, className, ...htmlProps } = props;

        return (
            <svg
                ref={ref}
                focusable="false"
                className={clsx(
                    styles['a-icon'],
                    styles[`a-icon--${size}`],
                    className
                )}
                {...htmlProps}
            >
                <use
                    xlinkHref={xlinkHref ? xlinkHref : `#aurora-icons_${name}`}
                ></use>
            </svg>
        );
    })
);

Icon.displayName = 'Icon';
Icon.defaultProps = {
    size: 's',
};

Icon.propTypes = {
    /**
     * Icon href imported by parent component
     * Using this makes component to ignore prop 'name'
     */
    xlinkHref: PropTypes.string,

    /**
     * Icon name from aurora package a-icon
     */
    name: PropTypes.string,

    /**
     * Icon size: xs, s, m, l, xl, xxl
     */
    size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl']),

    /**
     * @ignore
     */
    className: PropTypes.string,
};
