import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Icon } from '@workhuman/react-aurora-icon';

import styles from './TextInput.module.scss';

export const TextInputGroup = React.memo(
    React.forwardRef((props, ref) => {
        const {
            id,
            label,
            innerIcon,
            outerIcon,
            children,
            className,
            onChange = () => {},
            ...htmlProps
        } = props;

        return (
            <div className={styles['a-input-group']}>
                <input
                    ref={ref}
                    id={id}
                    data-testid={id}
                    type="text"
                    name={id}
                    className={clsx(styles['a-input-control'], className)}
                    aria-label={label}
                    onChange={onChange}
                    {...htmlProps}
                />
                {children}
                {innerIcon || outerIcon ? (
                    <Icon
                        className={
                            styles[
                                innerIcon ? 'a-control-icon' : 'a-input-icon'
                            ]
                        }
                        size="s"
                        name={innerIcon || outerIcon}
                    />
                ) : null}
            </div>
        );
    })
);

TextInputGroup.displayName = 'TextInputGroup';

TextInputGroup.propTypes = {
    /**
     * Input id
     */
    id: PropTypes.string.isRequired,

    /**
     * Input label
     */
    label: PropTypes.string,

    /**
     * Input inner icon
     */
    innerIcon: PropTypes.string,

    /**
     * Input outer icon
     */
    outerIcon: PropTypes.string,

    /**
     * Input onChange method
     */
    onChange: PropTypes.func,

    /**
     * Input child nodes
     */
    children: PropTypes.node,

    /**
     * @ignore
     */
    className: PropTypes.string,
};
