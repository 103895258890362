import { useState, useEffect } from 'react';

const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            setDebouncedValue(value);

            /**
             * In order to support typeahead, value cleanups itself
             * after some time, to make different searches.
             */
            const handler = setTimeout(() => {
                setDebouncedValue('');
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
};

export default useDebounce;
