import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button } from '@workhuman/react-aurora-button';
import { Icon } from '@workhuman/react-aurora-icon';

import styles from './InfoPanel.module.scss';

export const InfoPanel = React.memo((props) => {
    const {
        iconName,
        heading,
        description,
        actionUrl,
        actionHandler,
        actionLabel,
        infoPanelHeader,
        infoPanelMessage,
        infoPanelFooter,
        className,
        ...htmlProps
    } = props;
    return (
        <section
            className={clsx(styles['infoPanel'], className)}
            {...htmlProps}
        >
            {infoPanelHeader}

            <div className={styles['infoPanel-body']}>
                {iconName && (
                    <Icon
                        name={iconName}
                        size="xxl"
                        className={styles['a-icon--iconColor']}
                    />
                )}

                <h1
                    className={styles['a-typography--headline1']}
                    data-testid="infoPanel-heading"
                >
                    {heading}
                </h1>

                {infoPanelMessage}

                <p className={styles['a-typography--body1']}>{description}</p>

                {actionHandler ? (
                    <div className={styles['infoPanel-actions']}>
                        <Button
                            variant="primary"
                            data-testid="infoPanel-button"
                            onClick={(e) => actionHandler(e)}
                        >
                            {actionLabel}
                        </Button>
                    </div>
                ) : null}

                {actionUrl ? (
                    <div className={styles['infoPanel-actions']}>
                        <Button
                            variant="primary"
                            data-testid="infopanel-link"
                            as="a"
                            href={actionUrl}
                        >
                            {actionLabel}
                        </Button>
                    </div>
                ) : null}
            </div>

            {infoPanelFooter}
        </section>
    );
});

InfoPanel.displayName = 'InfoPanel';

InfoPanel.defaultProps = {
    iconName: undefined,
    heading: undefined,
    description: undefined,
    actionUrl: undefined,
    actionHandler: undefined,
    actionLabel: undefined,
    infoPanelHeader: undefined,
    infoPanelMessage: undefined,
    infoPanelFooter: undefined,
};

InfoPanel.propTypes = {
    /**
     * Icon name from aurora package a-icon
     */
    iconName: PropTypes.string,

    /**
     * Section heading
     */
    heading: PropTypes.string,

    /**
     * Description text for the section
     */
    description: PropTypes.string,

    /**
     * Button or Link text
     */
    actionLabel: PropTypes.string,

    /**
     * Link URL
     */
    actionUrl: PropTypes.string,

    /**
     * Callback Function
     */
    actionHandler: PropTypes.func,

    /**
     * Element, that can be passed through props and will be rendered
     * on the top of the section
     */
    infoPanelHeader: PropTypes.node,

    /**
     * Element, that can be passed through props and will be rendered
     * between header and description
     */
    infoPanelMessage: PropTypes.node,

    /**
     * Element, that can be passed through props and will be rendered
     * on the bottom of the section
     */
    infoPanelFooter: PropTypes.node,

    /**
     * @ignore
     */
    className: PropTypes.string,
};
