import { useEffect } from 'react';
import { warning } from '../warning';

/**
 * A hook to handle pressing ESC button and hide element.
 *
 * @param {Boolean} handleEsc Flag is ESC closes the dialog.
 * @param {Function} hide A function to handle hide dialog.
 *
 * @see documentation A11Y: https://www.w3.org/TR/wai-aria-practices/#dialog_modal
 */
export const useHandleEscape = ({ handleEsc, hide }) => {
    useEffect(() => {
        if (handleEsc) {
            const handleEsc = (ev) => {
                if (!hide) {
                    warning(
                        '[Dialog]:',
                        'hadleEsc is true, but hide is not provided'
                    );
                    return;
                }

                if (ev.key.toLowerCase() === 'escape') {
                    ev.stopPropagation();
                    hide();
                }
            };

            document.addEventListener('keydown', handleEsc);

            return () => {
                document.removeEventListener('keydown', handleEsc);
            };
        }
    }, [hide, handleEsc]);
};
