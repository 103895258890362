/**
 * Reusing the `ref` from `forwardRef` with `React hooks`.
 *
 * This is to solve tricky `Ref` issue, which I think shuoldn't be so unobvious:
 * @see https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 * @see https://github.com/reakit/reakit/blob/master/packages/reakit-utils/src/mergeRefs.ts
 *
 * @param  {...any} refs React refs
 */
export function mergeRefs(...refs) {
    // Filter out falsy values
    const filteredRefs = refs.filter(Boolean);

    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];

    return (instance) => {
        // eslint-disable-next-line no-unused-vars
        for (const ref of filteredRefs) {
            if (typeof ref === 'function') {
                ref(instance);
            } else if (ref) {
                ref.current = instance;
            }
        }
    };
}
