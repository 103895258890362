import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { capitalize } from '@workhuman/react-aurora-utils';
import { Icon } from '@workhuman/react-aurora-icon';

import { useButton } from './useButton';
import styles from './Button.module.scss';

/**
 * Aurora button component.
 */
export const Button = React.memo(
    React.forwardRef((props, ref) => {
        const {
            as,
            variant,
            severity,
            focusable,
            children,
            className,
            iconName,
            xlinkHref,
            ...htmlProps
        } = props;

        const TagName = as;
        const buttonProps = useButton({ ref, focusable, ...htmlProps });

        return (
            <TagName
                className={clsx(
                    styles['a-button'],
                    variant && styles[`a-button--${variant}`],
                    severity &&
                        styles[`a-button--${variant}${capitalize(severity)}`],
                    className
                )}
                {...buttonProps}
            >
                {(xlinkHref && (
                    <Icon
                        className={styles['a-button-icon']}
                        xlinkHref={xlinkHref}
                    ></Icon>
                )) ||
                    (iconName && (
                        <Icon
                            className={styles['a-button-icon']}
                            name={iconName}
                        ></Icon>
                    ))}
                <span className={styles['a-button-text']}>{children}</span>
            </TagName>
        );
    })
);

Button.displayName = 'Button';

Button.defaultProps = {
    as: 'button',
    severity: undefined,
    focusable: undefined,
};

Button.propTypes = {
    /**
     * HTML Element name to render.
     */
    as: PropTypes.string,

    /**
     * The content of the component.
     */
    children: PropTypes.node,

    /**
     * @ignore
     */
    className: PropTypes.string,

    /**
     * When element is `disabled` it may still be `focusable`.
     */
    focusable: PropTypes.bool,

    /**
     * Button type.
     */
    variant: PropTypes.PropTypes.oneOf(['primary', 'outlined', 'transparent']),

    /**
     * Severity type.
     */
    severity: PropTypes.PropTypes.oneOf([
        'info',
        'success',
        'danger',
        'warning',
    ]),

    /**
     * Icon href imported by parent component
     * Using this makes component to ignore prop 'name'
     */
    xlinkHref: PropTypes.string,

    /**
     * Icon name from aurora package a-icon
     */
    iconName: PropTypes.string,
};
