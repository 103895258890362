import { useState, useEffect, useCallback } from 'react';

const useDropdown = (dropDownMenu, dropDownToggler) => {
    const [isOpened, setDropDownState] = useState(false);

    /**
     * Shows dropdown options
     */
    const showDropDown = useCallback(() => {
        setDropDownState(true);
        dropDownMenu.current && dropDownMenu.current.focus();
    }, [isOpened]);

    /**
     * Hides dropdown options
     */
    const hideDropDown = useCallback(() => {
        setDropDownState(false);
        dropDownToggler.current && dropDownToggler.current.focus();
    }, [isOpened]);

    /**
     * Toggles dropdown options between states
     */
    const toggleDropDown = (toggleState) => {
        if (toggleState) {
            showDropDown();
        } else {
            hideDropDown();
        }
    };

    /**
     * Hide listbox on click outside of it, on item mouse click
     * Show/hide listbox on select button click
     */

    const handleWindowClick = useCallback(
        (event) => {
            const clickOnToggler =
                dropDownToggler.current &&
                (event.target === dropDownToggler.current ||
                    dropDownToggler.current.contains(event.target));

            const clickOnDropDownMenu =
                dropDownMenu.current &&
                (event.target === dropDownMenu.current ||
                    dropDownMenu.current.contains(event.target));

            if ((!clickOnToggler || !clickOnDropDownMenu) && isOpened) {
                toggleDropDown(!isOpened);
            }

            if (clickOnToggler) {
                dropDownMenu.current && dropDownMenu.current.focus();
            }
        },
        [isOpened]
    );

    /**
     * Keyboard functionality for Select button.
     *
     * | Key        | Function                                             |
     * | ---------- | ---------------------------------------------------- |
     * | Down Arrow | If the listbox is collapsed, also expands the list.  |
     * | Up Arrow   | If the listbox is collapsed, also expands the list.  |
     * | Return     | If the focus is on the button, expands the listbox   |
     * |            | and places focus on the currently selected option    |
     * |            | in the list.                                         |
     *
     * @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html
     *
     * @param event {KeyboardEvent} keydown event.
     */

    const handleButtonKeydown = (event) => {
        const keys = {
            RETURN: 13,
            UP: 38,
            DOWN: 40,
        };

        switch (event.keyCode) {
            case keys.UP:
            case keys.DOWN:
                event.preventDefault();
                showDropDown();
                break;
            case keys.RETURN:
                event.preventDefault();
                if (isOpened) {
                    hideDropDown();
                } else {
                    showDropDown();
                }
                break;
            default:
                break;
        }
    };

    /**
     * Keyboard functionality for Select listbox.
     *
     * | Key        | Function                                          |
     * | ---------- | ------------------------------------------------- |
     * | Escape     | If the listbox is displayed closes it.            |
     *
     * Requirements based on W3.org specifications:
     * @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html
     *
     * Core keyboard interaction handled by `lisbox`:
     * @see https://git1.corp.globoforce.com/npm/react-aurora/blob/master/packages/listbox/lib/Listbox.js
     *
     * @param event {KeyboardEvent} keydown event.
     */

    const handleListboxKeydown = (event) => {
        const keys = {
            ESC: 27,
        };

        switch (event.keyCode) {
            case keys.ESC:
                hideDropDown();
                break;
            default:
                break;
        }
    };

    // SIDE EFFECTS

    /**
     * Handle select's lisbox keydown.
     */
    useEffect(() => {
        dropDownMenu.current &&
            dropDownMenu.current.addEventListener(
                'keydown',
                handleListboxKeydown
            );
        return () =>
            dropDownMenu.current &&
            dropDownMenu.current.removeEventListener(
                'keydown',
                handleListboxKeydown
            );
    });

    /**
     * Handle window click.
     */
    useEffect(() => {
        window.addEventListener('click', handleWindowClick);
        return () => window.removeEventListener('click', handleWindowClick);
    });

    /**
     * Handle select's button keydown.
     */
    useEffect(() => {
        dropDownToggler.current &&
            dropDownToggler.current.addEventListener(
                'keydown',
                handleButtonKeydown
            );
        return () =>
            dropDownToggler.current &&
            dropDownToggler.current.removeEventListener(
                'keydown',
                handleButtonKeydown
            );
    });

    return [isOpened, toggleDropDown];
};

export default useDropdown;
