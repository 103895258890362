import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { mergeRefs } from '@workhuman/react-aurora-utils';
import { Button } from '@workhuman/react-aurora-button';
import { Icon } from '@workhuman/react-aurora-icon';
import { Listbox } from '@workhuman/react-aurora-listbox';

import useDropdown from './useDropdown';

import styles from './Select.module.scss';

export const Select = React.memo(
    React.forwardRef((props, ref) => {
        const {
            id,
            selectPlaceholder,
            selectData,
            valueField,
            searchBy,
            selectValue,
            label,
            labelledBy,
            renderItem = () => {},
            onChange = () => {},
            className,
            buttonClassName,
            enableShowMenuAbove = true,
            children,
            ...htmlProps
        } = props;

        const dropDownToggler = React.useRef(null);
        const dropDownMenu = React.useRef();
        const [isOpened, toggleListbox] = useDropdown(
            dropDownMenu,
            dropDownToggler
        );

        const initialSelectedValueIndex = selectValue
            ? valueField
                ? selectData.findIndex(
                      (item) => item[valueField] === selectValue[valueField]
                  )
                : selectData.findIndex((item) => item === selectValue)
            : 0;
        const [selectedValueIndex, setSelectedValueIndex] = useState(
            initialSelectedValueIndex
        );
        const selectTextValue = valueField
            ? selectValue[valueField]
            : selectValue; // Text of selected value.
        const [toggleText, setToggleText] = useState(
            selectTextValue ? selectTextValue : selectPlaceholder
        );
        const listBoxData = { options: selectData };
        // Setting default value out of view to allow for height calculation.
        const [menuTopValue, setMenuTopValue] = useState('-9999px');
        const [displayMenuAbove, setDisplayMenuAbove] = useState(false);

        const setSelectedValue = useCallback(
            (index) => {
                const selectTextValue = valueField
                    ? selectData[index][valueField]
                    : selectData[index];
                setSelectedValueIndex(index);
                setToggleText(selectTextValue);
                toggleListbox(false);
                onChange(selectTextValue);
            },
            [selectedValueIndex, selectData]
        );

        // TODO: need help how to refactor the code below
        // This is necessary for the situation when one select depends on another, e.g. need to set/reset select values on another select change
        useEffect(() => {
            if (selectValue && selectValue !== selectData[selectedValueIndex]) {
                setSelectedValue(
                    valueField
                        ? selectData.findIndex(
                              (item) =>
                                  item[valueField] === selectValue[valueField]
                          )
                        : selectData.findIndex((item) => item === selectValue)
                );
            } else if (selectValue === '') {
                setSelectedValueIndex(0);
                setToggleText(selectPlaceholder);
                toggleListbox(false);
                onChange('');
            }
        }, [selectValue]);

        /**
         * - The dropdown menu should appear below the select element when
         * there is enough space below to display it there.
         * - The dropdown menu should appear above the select element when
         * there is not enough space below to display it there.
         */
        const calculateDropdownMenuPosition = () => {
            const dropdownMenuHeight = dropDownMenu.current.getBoundingClientRect()
                .height;
            const dropdownTogglerPos = dropDownToggler.current.getBoundingClientRect()
                .bottom;

            const displayMenuAbove =
                dropdownMenuHeight + dropdownTogglerPos >= window.innerHeight;

            setDisplayMenuAbove(displayMenuAbove);
            setMenuTopValue('');
        };

        /**
         * Calculate the top position for menu when flag enabled.
         * Reset menu top position to out of window view when closed.
         */
        useEffect(() => {
            if (enableShowMenuAbove) {
                if (isOpened) {
                    calculateDropdownMenuPosition();
                } else {
                    setMenuTopValue('-9999px');
                }
            }
        }, [isOpened]);

        return (
            <div className={clsx(styles['a-select'], className)}>
                <Button
                    id={`select_${id}`}
                    data-testid={`select_${id}_button`}
                    ref={mergeRefs(dropDownToggler, ref)}
                    onClick={(event) => {
                        event.preventDefault();
                        toggleListbox(true);
                    }}
                    variant={'transparent'}
                    className={clsx(
                        styles['a-select-button'],
                        styles['a-select-button--withDropdownIcon'],
                        styles[isOpened ? 'a-select-button--shown' : ''],
                        buttonClassName
                    )}
                    aria-labelledby={`select_${id}`}
                    aria-expanded={isOpened}
                    {...htmlProps}
                >
                    {toggleText}
                    {children}
                    <div className={styles['a-select-button-iconContainer']}>
                        <Icon
                            className={styles['a-select-button-icon']}
                            name={'chevron-up'}
                            size={'xs'}
                        />
                    </div>
                </Button>
                {isOpened && (
                    <Listbox
                        id={id}
                        data-testid={`select_${id}_listbox`}
                        ref={dropDownMenu}
                        className={clsx(styles['a-select-listbox'], {
                            [styles['a-select--above']]: displayMenuAbove,
                        })}
                        itemClassName={styles['a-select-listbox-item']}
                        listBoxData={listBoxData}
                        searchBy={searchBy}
                        selectedValueIndex={selectedValueIndex}
                        onClick={setSelectedValue}
                        onKeyDown={setSelectedValue}
                        label={label}
                        labelledby={labelledBy}
                        renderItem={renderItem}
                        style={enableShowMenuAbove ? { top: menuTopValue } : {}}
                    />
                )}
            </div>
        );
    })
);

Select.displayName = 'Select';

Select.propTypes = {
    /**
     * Select id
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Placeholder string, renders when there is no selected value
     */
    selectPlaceholder: PropTypes.string,

    /**
     * Array of options with data to represent
     */
    selectData: PropTypes.array.isRequired,

    /**
     * Listbox description. Aria-label value
     */
    label: PropTypes.string,

    /**
     * Aria-labelledby attribute value
     * DOM Node id expected.
     */
    labelledBy: PropTypes.string,

    /**
     * Field name to represent in input
     * if complex data object is provided
     */
    valueField: PropTypes.string,

    /**
     * Selected item from `selectData.options` list.
     */
    selectValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number,
    ]),

    /**
     * Field name to search by
     */
    searchBy: PropTypes.string,

    /**
     * Listbox function that renders markup for listbox option
     */
    renderItem: PropTypes.func,

    /**
     * Combobox onChange method
     */
    onChange: PropTypes.func,

    /**
     * @ignore
     */
    className: PropTypes.string,

    /**
     * @ignore
     */
    buttonClassName: PropTypes.string,

    /**
     * Flag to show dropdown menu above button
     * when there is not enough space below.
     * Will show below if there is enough space.
     * Default is true.
     */
    enableShowMenuAbove: PropTypes.bool,

    /**
     * Child elements
     */
    children: PropTypes.node,
};
