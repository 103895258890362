import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Icon } from '@workhuman/react-aurora-icon';

import styles from './Alert.module.scss';

const ICON_TYPES = {
    info: 'info-filled',
    warning: 'info-filled',
    success: 'correct-filled',
    danger: 'danger-filled',
};

export const Alert = React.memo(
    React.forwardRef((props, ref) => {
        const { text, severity, className, ...htmlProps } = props;

        return (
            <div
                ref={ref}
                role="alert"
                className={clsx(
                    styles['a-alert'],
                    styles[`a-alert--${severity}`],
                    className
                )}
                {...htmlProps}
            >
                <Icon
                    name={ICON_TYPES[severity]}
                    className={clsx(
                        styles['a-alert-icon'],
                        styles[`a-alert-icon--${severity}`]
                    )}
                    size={'s'}
                />
                <div className={clsx(styles['a-alert-text'])}>{text}</div>
            </div>
        );
    })
);

Alert.displayName = 'Alert';

Alert.propTypes = {
    /**
     * Alert message
     */
    text: PropTypes.string.isRequired,

    /**
     * Alert severity
     */
    severity: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),

    /**
     * @ignore
     */
    className: PropTypes.string,
};
