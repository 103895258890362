import { useEffect } from 'react';
import { getFirstTabbableIn, ensureFocus } from '../tabbable';
import { warning } from '../warning';

/**
 * A hook to handle initial focus on element.
 *
 * @param {RefObject} dialogRef Dialog ref.
 * @param {Object} options Options.
 * @param {Boolean} options.autoFocusOnShow Flag is set initial focus.
 * @param {RefObject} options.initialFocusRef Initial focused element ref.
 * @param {Boolean} optionsvisible Flag is element visible.
 * @param {Boolean} options.closeByClickOutside Flag is clicks outside of element are handled.
 *
 * @see documentation A11Y: https://www.w3.org/TR/wai-aria-practices/#dialog_modal
 */
export const useFocusOnShow = (
    dialogRef,
    { autoFocusOnShow, initialFocusRef, visible, closeByClickOutside }
) => {
    const shouldFocus = visible && autoFocusOnShow && closeByClickOutside;

    useEffect(() => {
        if (!shouldFocus) return;

        if (shouldFocus && !dialogRef.current) {
            warning(
                '[Dialog]: ',
                'shouldFocus is true, but dialogRef is not provided'
            );
            return;
        }

        let dialog = dialogRef.current;

        /**
         * Focus on initial element.
         */
        if (initialFocusRef && initialFocusRef.current) {
            initialFocusRef.current.focus({ preventScroll: true });
            return;
        }

        /**
         * Focus on first tabbable element in dialog.
         */
        let tabbable = getFirstTabbableIn(dialog);
        const isActive = () => dialog.contains(document.activeElement);

        if (tabbable) {
            ensureFocus(tabbable, { preventScroll: true, isActive });
        } else {
            ensureFocus(dialog, { preventScroll: true, isActive });
            warning(
                dialog.tabIndex === null || dialog.tabIndex < 0,
                '[Dialog]:',
                'It"s recommended to have at least one tabbable element inside dialog. The dialog element has been automatically focused.',
                'Pass tabIndex={0} to avoid this message and make dialog focused by default.'
            );
        }
    }, [shouldFocus, dialogRef, initialFocusRef]);
};
